import { getCell, setCell, getSheetIndex } from './../index';
import { getCellAddress, getRangeIndexes, beforeCellUpdate, workbookEditOperation } from './index';
/**
 * Check whether the text is formula or not.
 *
 * @param {string} text - Specify the text.
 * @param {boolean} isEditing - Specify the isEditing.
 * @returns {boolean} - Check whether the text is formula or not.
 */
export function checkIsFormula(text, isEditing) {
    return text && text[0] === '=' && (text.length > 1 || isEditing);
}
/**
 * Check whether the value is cell reference or not.
 *
 * @param {string} value - Specify the value to check.
 * @returns {boolean} - Returns boolean value
 */
export function isCellReference(value) {
    var range = value;
    range = range.split('$').join('');
    if (range.indexOf(':') > -1) {
        var rangeSplit = range.split(':');
        if (isValidCellReference(rangeSplit[0]) && isValidCellReference(rangeSplit[1])) {
            return true;
        }
    }
    else if (range.indexOf(':') < 0) {
        if (isValidCellReference(range)) {
            return true;
        }
    }
    return false;
}
/**
 * Check whether the value is character or not.
 *
 * @param {string} value - Specify the value to check.
 * @returns {boolean} - Returns boolean value
 */
export function isChar(value) {
    if ((value.charCodeAt(0) >= 65 && value.charCodeAt(0) <= 90) || (value.charCodeAt(0) >= 97 && value.charCodeAt(0) <= 122)) {
        return true;
    }
    return false;
}
/**
 * Check whether the range selection is on complete row.
 *
 * @param {SheetModel} sheet - Specify the sheet.
 * @param {number[]} range - Specify the range index.
 * @returns {boolean} - Returns boolean value
 * @hidden
 */
export function isRowSelected(sheet, range) {
    return range[1] === 0 && range[3] === sheet.colCount - 1;
}
/**
 * Check whether the range selection is on complete column.
 *
 * @param {SheetModel} sheet - Specify the sheet.
 * @param {number[]} range - Specify the range index.
 * @returns {boolean} - Returns boolean value
 * @hidden
 */
export function isColumnSelected(sheet, range) {
    return range[0] === 0 && range[2] === sheet.rowCount - 1;
}
/**
 * @param {number[]} range - Specify the range
 * @param {number} rowIdx - Specify the row index
 * @param {number} colIdx - Specify the col index
 * @returns {boolean} - Returns boolean value
 */
export function inRange(range, rowIdx, colIdx) {
    return range && (rowIdx >= range[0] && rowIdx <= range[2] && colIdx >= range[1] && colIdx <= range[3]);
}
/** @hidden
 * @param {number[]} range - Specify the range
 * @param {number[]} testRange - Specify the test range
 * @param {boolean} isModify - Specify the boolean value
 * @returns {boolean} - Returns boolean value
 */
export function isInRange(range, testRange, isModify) {
    var inRange = range[0] <= testRange[0] && range[2] >= testRange[2] && range[1] <= testRange[1] && range[3] >= testRange[3];
    if (inRange) {
        return true;
    }
    if (isModify) {
        if (testRange[0] < range[0] && testRange[2] < range[0] || testRange[0] > range[2] && testRange[2] > range[2]) {
            return false;
        }
        else {
            if (testRange[0] < range[0] && testRange[2] > range[0]) {
                testRange[0] = range[0];
                inRange = true;
            }
            if (testRange[2] > range[2]) {
                testRange[2] = range[2];
                inRange = true;
            }
        }
        if (testRange[1] < range[1] && testRange[3] < range[1] || testRange[1] > range[3] && testRange[3] > range[3]) {
            return false;
        }
        else {
            if (testRange[1] < range[1] && testRange[3] > range[1]) {
                testRange[1] = range[1];
                inRange = true;
            }
            if (testRange[3] > range[3]) {
                testRange[3] = range[3];
                inRange = true;
            }
        }
    }
    return inRange;
}
/**
 * Check whether the cell is locked or not
 *
 * @param {CellModel} cell - Specify the cell.
 * @param {ColumnModel} column - Specify the column.
 * @returns {boolean} - Returns boolean value
 * @hidden
 */
export function isLocked(cell, column) {
    if (!cell) {
        cell = {};
    }
    if (cell.isLocked) {
        return true;
    }
    else if (cell.isLocked === false) {
        return false;
    }
    else if (column && column.isLocked) {
        return true;
    }
    else if (!cell.isLocked && (column && column.isLocked !== false)) {
        return true;
    }
    return false;
}
/**
 * Check whether the value is cell reference or not.
 *
 * @param {string} value - Specify the value to check.
 * @returns {boolean} - Returns boolean value
 * @hidden
 */
export function isValidCellReference(value) {
    var text = value;
    var startNum = 0;
    var endNum = 0;
    var j = 0;
    var numArr = [89, 71, 69];
    // XFD is the last column, for that we are using ascii values of Z, G, E (89, 71, 69) to restrict the flow.
    var cellText = '';
    var textLength = text.length;
    for (var i = 0; i < textLength; i++) {
        if (isChar(text[i])) {
            endNum++;
        }
    }
    cellText = text.substring(startNum, endNum);
    var cellTextLength = cellText.length;
    if (cellTextLength !== textLength) {
        if (cellTextLength < 4) {
            if (textLength !== 1 && (isNaN(parseInt(text, 10)))) {
                while (j < cellTextLength) {
                    if ((cellText[j]) && cellText[j].charCodeAt(0) < numArr[j]) {
                        j++;
                        continue;
                    }
                    else if (!(cellText[j]) && j > 0) {
                        break;
                    }
                    else {
                        return false;
                    }
                }
                var cellNumber = parseFloat(text.substring(endNum, textLength));
                if (cellNumber > 0 && cellNumber < 1048577) { // 1048576 - Maximum number of rows in excel.
                    return true;
                }
            }
        }
    }
    return false;
}
/**
 * @hidden
 * @param {SheetModel} sheet - Specify the sheet
 * @param {number} index - specify the index
 * @param {boolean} increase - specify the boolean value.
 * @param {string} layout - specify the string
 * @returns {number} - To skip the hidden index
 *
 */
export function skipHiddenIdx(sheet, index, increase, layout) {
    if (layout === void 0) { layout = 'rows'; }
    if (index < 0) {
        index = -1;
    }
    if ((sheet[layout])[index] && (sheet[layout])[index].hidden) {
        if (increase) {
            index++;
        }
        else {
            index--;
        }
        index = skipHiddenIdx(sheet, index, increase, layout);
    }
    return index;
}
/**
 * @param {CellStyleModel} style - Cell style.
 * @param {boolean} onActionUpdate - Specifies the action.
 * @returns {boolean} - retruns `true` is height needs to be checked.
 * @hidden
 */
export function isHeightCheckNeeded(style, onActionUpdate) {
    var keys = Object.keys(style);
    return (onActionUpdate ? keys.indexOf('fontSize') > -1 : keys.indexOf('fontSize') > -1
        && Number(style.fontSize.split('pt')[0]) > 12) || keys.indexOf('fontFamily') > -1 || keys.indexOf('borderTop') > -1
        || keys.indexOf('borderBottom') > -1;
}
/**
 * @param {number[]} currIndexes - current indexes in which formula get updated
 * @param {number[]} prevIndexes - copied indexes
 * @param {SheetModel} sheet - sheet model
 * @param {CellModel} prevCell - copied or prev cell
 * @returns {string} - retruns updated formula
 * @hidden
 */
export function getUpdatedFormula(currIndexes, prevIndexes, sheet, prevCell) {
    var cIdxValue;
    var cell;
    if (prevIndexes) {
        cell = prevCell || getCell(prevIndexes[0], prevIndexes[1], sheet, false, true);
        cIdxValue = cell.formula ? cell.formula.toUpperCase() : '';
    }
    if (cIdxValue) {
        if (cIdxValue.indexOf('=') === 0) {
            cIdxValue = cIdxValue.slice(1);
        }
        cIdxValue = cIdxValue.split('(').join(',').split(')').join(',');
        var formulaOperators = ['+', '-', '*', '/', '>=', '<=', '<>', '>', '<', '=', '%'];
        var splitArray = void 0;
        var value = cIdxValue;
        for (var i = 0; i < formulaOperators.length; i++) {
            splitArray = value.split(formulaOperators[i]);
            value = splitArray.join(',');
        }
        splitArray = value.split(',');
        var newAddress = [];
        var newRef = void 0;
        var refObj = void 0;
        for (var j = 0; j < splitArray.length; j++) {
            if (isCellReference(splitArray[j])) {
                var range = getRangeIndexes(splitArray[j]);
                var newRange = [currIndexes[0] - (prevIndexes[0] - range[0]), currIndexes[1] - (prevIndexes[1] - range[1]),
                    currIndexes[0] - (prevIndexes[0] - range[2]), currIndexes[1] - (prevIndexes[1] - range[3])];
                if (newRange[0] < 0 || newRange[1] < 0 || newRange[2] < 0 || newRange[3] < 0) {
                    newRef = '#REF!';
                }
                else {
                    newRef = getCellAddress(newRange[0], newRange[1]);
                    if (splitArray[j].includes(':')) {
                        newRef += (':' + getCellAddress(newRange[2], newRange[3]));
                    }
                    newRef = isCellReference(newRef) ? newRef : '#REF!';
                }
                refObj = {};
                refObj[splitArray[j]] = newRef;
                if (splitArray[j].includes(':')) {
                    newAddress.splice(0, 0, refObj);
                }
                else {
                    newAddress.push(refObj);
                }
            }
        }
        var objKey = void 0;
        cIdxValue = cell.formula;
        for (var j = 0; j < newAddress.length; j++) {
            objKey = Object.keys(newAddress[j])[0];
            cIdxValue = cIdxValue.replace(new RegExp(objKey, 'gi'), newAddress[j][objKey].toUpperCase());
        }
        return cIdxValue;
    }
    else {
        return null;
    }
}
/**@hidden */
export function updateCell(context, sheet, prop) {
    var args = { cell: prop.cell, rowIndex: prop.rowIdx, colIndex: prop.colIdx, cancel: false, sheet: sheet.name };
    if (!prop.preventEvt) { // Prevent event triggering for public method cell update.
        context.trigger(beforeCellUpdate, args);
    }
    if (!prop.eventOnly && !args.cancel) { // `eventOnly` - To trigger event event and return without cell model update.
        if (prop.valChange) {
            var prevCell = getCell(args.rowIndex, args.colIndex, sheet);
            var prevCellVal = !prop.preventEvt && context.getDisplayText(prevCell);
            var isFormulaCell = !!(prevCell && prevCell.formula);
            setCell(args.rowIndex, args.colIndex, sheet, args.cell, !prop.pvtExtend);
            var cell = getCell(args.rowIndex, args.colIndex, sheet, false, true);
            context.notify(workbookEditOperation, { action: 'updateCellValue', address: [args.rowIndex, args.colIndex], sheetIndex: getSheetIndex(context, sheet.name), value: isFormulaCell && !cell.formula ? '' : (cell.formula || cell.value ||
                    (cell.value === 0 ? '0' : '')) });
            if (prop.requestType && args.cell === null) {
                setCell(args.rowIndex, args.colIndex, sheet, args.cell, !prop.pvtExtend);
            }
            if (prop.cellDelete) {
                delete cell.value;
                delete cell.formula;
                delete cell.hyperlink;
            }
            if (prop.uiRefresh) {
                context.serviceLocator.getService('cell').refresh(args.rowIndex, args.colIndex, prop.lastCell, prop.td, prop.checkCf, prop.checkWrap);
            }
            if (!prop.preventEvt) {
                var cellDisplayText = context.getDisplayText(cell);
                if (cellDisplayText !== prevCellVal) {
                    var cellValue = getCell(args.rowIndex, args.colIndex, sheet, false, true).value;
                    cellValue = cellValue || (cellValue === 0 ? '0' : '');
                    var evtArgs = { value: cellValue, oldValue: prevCellVal, formula: cell.formula || '',
                        address: sheet.name + "!" + getCellAddress(args.rowIndex, args.colIndex), displayText: cellDisplayText };
                    if (prop.requestType) {
                        evtArgs.requestType = prop.requestType;
                    }
                    context.trigger('cellSave', evtArgs);
                }
            }
        }
        else {
            setCell(args.rowIndex, args.colIndex, sheet, args.cell, !prop.pvtExtend);
        }
    }
    return args.cancel;
}
/**
 * @param {number} rowIdx - row index
 * @param {number} colIdx - column index
 * @param {SheetModel} sheet - sheet model
 * @returns {number[]} - retruns data range
 * @hidden
 */
export function getDataRange(rowIdx, colIdx, sheet) {
    var i = 0;
    var j = 0;
    var loopLength = 0;
    var length = sheet.usedRange.rowIndex + sheet.usedRange.colIndex;
    var startCell = { rowIndex: rowIdx, colIndex: colIdx };
    var endCell = { rowIndex: rowIdx, colIndex: colIdx };
    for (i = 1; i < length + 1; i++) {
        for (j = -loopLength; j < loopLength + 1; j++) { // start from right
            if (getCell(rowIdx + j, colIdx + i, sheet, null, true).value) {
                endCell.rowIndex = endCell.rowIndex > rowIdx + j ? endCell.rowIndex : rowIdx + j;
                endCell.colIndex = endCell.colIndex > colIdx + i ? endCell.colIndex : colIdx + i;
            }
        }
        if (getCell(rowIdx + i, colIdx + i, sheet, null, true).value) {
            endCell.rowIndex = endCell.rowIndex > rowIdx + i ? endCell.rowIndex : rowIdx + i;
            endCell.colIndex = endCell.colIndex > colIdx + i ? endCell.colIndex : colIdx + i;
        }
        for (j = -loopLength; j < loopLength + 1; j++) {
            if (getCell(rowIdx + i, colIdx + j, sheet, null, true).value) {
                endCell.rowIndex = endCell.rowIndex > rowIdx + i ? endCell.rowIndex : rowIdx + i;
                endCell.colIndex = endCell.colIndex > colIdx + j ? endCell.colIndex : colIdx + j;
            }
        }
        if (getCell(rowIdx + i, colIdx - i, sheet, null, true).value) {
            endCell.rowIndex = endCell.rowIndex > rowIdx + i ? endCell.rowIndex : rowIdx + i;
            startCell.colIndex = startCell.colIndex < colIdx - i ? startCell.colIndex : colIdx - i;
        }
        for (j = -loopLength; j < loopLength + 1; j++) {
            if (getCell(rowIdx + j, colIdx - i, sheet, null, true).value) {
                startCell.rowIndex = startCell.rowIndex < rowIdx + j ? startCell.rowIndex : rowIdx + j;
                startCell.colIndex = startCell.colIndex < colIdx - i ? startCell.colIndex : colIdx - i;
                endCell.rowIndex = endCell.rowIndex > rowIdx + j ? endCell.rowIndex : rowIdx + j;
            }
        }
        if (getCell(rowIdx - i, colIdx - i, sheet, null, true).value) {
            startCell.rowIndex = startCell.rowIndex < rowIdx - i ? startCell.rowIndex : rowIdx - i;
            startCell.colIndex = startCell.colIndex < colIdx - i ? startCell.colIndex : colIdx - i;
        }
        for (j = -loopLength; j < loopLength + 1; j++) {
            if (getCell(rowIdx - i, colIdx + j, sheet, null, true).value) {
                startCell.rowIndex = startCell.rowIndex < rowIdx - i ? startCell.rowIndex : rowIdx - i;
                startCell.colIndex = startCell.colIndex < colIdx + j ? startCell.colIndex : colIdx + j;
                endCell.colIndex = endCell.colIndex > colIdx + j ? endCell.colIndex : colIdx + j;
            }
        }
        if (getCell(rowIdx - i, colIdx + i, sheet, null, true).value) {
            startCell.rowIndex = startCell.rowIndex < rowIdx - i ? startCell.rowIndex : rowIdx - i;
            endCell.colIndex = endCell.colIndex > colIdx + i ? endCell.colIndex : colIdx + i;
        }
        loopLength++;
    }
    return [startCell.rowIndex, startCell.colIndex, endCell.rowIndex, endCell.colIndex];
}
/**
 * @param {InsertDeleteModelArgs} args - row index
 * @param {number[]} formatRange - format range index
 * @returns {number[]} - retruns updated range
 * @hidden
 */
export function insertFormatRange(args, formatRange) {
    var sltRangeIndex = getRangeIndexes(args.model.selectedRange);
    var insertStartIndex = 0;
    var insertEndIndex = 0;
    if (args.modelType === 'Column') {
        if (args.insertType === "before") {
            if ((formatRange[1] <= sltRangeIndex[1] && formatRange[3] >= sltRangeIndex[1])) {
                insertStartIndex = 0;
                insertEndIndex = (sltRangeIndex[3] - sltRangeIndex[1]) + 1;
            }
            else if (sltRangeIndex[1] < formatRange[1]) {
                insertStartIndex = insertEndIndex = (sltRangeIndex[3] - sltRangeIndex[1]) + 1;
            }
        }
        else {
            if ((formatRange[1] <= sltRangeIndex[3] && formatRange[3] >= sltRangeIndex[3])) {
                insertStartIndex = 0;
                insertEndIndex = (sltRangeIndex[3] - sltRangeIndex[1]) + 1;
            }
            else if (sltRangeIndex[3] < formatRange[3]) {
                insertStartIndex = insertEndIndex = (sltRangeIndex[3] - sltRangeIndex[1]) + 1;
            }
        }
        return [formatRange[0], formatRange[1] + insertStartIndex, formatRange[2], formatRange[3] + insertEndIndex];
    }
    else {
        if (args.insertType === "above") {
            if ((formatRange[0] <= sltRangeIndex[0] && formatRange[2] >= sltRangeIndex[0])) {
                insertStartIndex = 0;
                insertEndIndex = (sltRangeIndex[2] - sltRangeIndex[0]) + 1;
            }
            else if (sltRangeIndex[0] < formatRange[0]) {
                insertStartIndex = insertEndIndex = (sltRangeIndex[2] - sltRangeIndex[0]) + 1;
            }
        }
        else {
            if ((formatRange[0] <= sltRangeIndex[2] && formatRange[2] >= sltRangeIndex[2])) {
                insertStartIndex = 0;
                insertEndIndex = (sltRangeIndex[2] - sltRangeIndex[0]) + 1;
            }
            else if (sltRangeIndex[2] < formatRange[2]) {
                insertStartIndex = insertEndIndex = (sltRangeIndex[2] - sltRangeIndex[0]) + 1;
            }
        }
        return [formatRange[0] + insertStartIndex, formatRange[1], formatRange[2] + insertEndIndex, formatRange[3]];
    }
}
/**
 * @param {InsertDeleteModelArgs} args - row index
 * @param {number[]} formatRange - cell range index
 * @returns {number[]} - retruns data range
 * @hidden
 */
export function deleteFormatRange(args, formatRange) {
    var cellRange;
    var deleteStartIndex = 0;
    var deleteEndIndex = 0;
    if (args.modelType === 'Column') {
        cellRange = [0, args.start, args.model.usedRange.rowIndex, args.end];
        if (cellRange[3] < formatRange[1]) {
            deleteStartIndex = deleteEndIndex = cellRange[3] - cellRange[1] + 1;
        }
        else if (cellRange[1] >= formatRange[1] && cellRange[3] <= formatRange[3]) {
            deleteEndIndex = cellRange[3] - cellRange[1] + 1;
        }
        else if (cellRange[1] >= formatRange[1] && cellRange[1] <= formatRange[3]) {
            deleteEndIndex = formatRange[3] - cellRange[1] + 1;
        }
        else if (cellRange[1] < formatRange[1] && cellRange[3] >= formatRange[1]) {
            deleteStartIndex = formatRange[1] - cellRange[1];
            deleteEndIndex = cellRange[3] - cellRange[1] + 1;
        }
        else if (cellRange[1] < formatRange[1] && cellRange[3] < formatRange[3]) {
            deleteStartIndex = (cellRange[3] - formatRange[1]) + (cellRange[3] - cellRange[1]) + 1;
            deleteEndIndex = cellRange[3] - cellRange[1] + 1;
        }
        return [formatRange[0], formatRange[1] - deleteStartIndex, formatRange[2], formatRange[3] - deleteEndIndex];
    }
    else {
        cellRange = [args.start, 0, args.end, args.model.usedRange.colIndex];
        if (cellRange[2] < formatRange[0]) {
            deleteStartIndex = deleteEndIndex = cellRange[2] - cellRange[0] + 1;
        }
        else if (cellRange[0] >= formatRange[0] && cellRange[2] <= formatRange[2]) {
            deleteEndIndex = cellRange[2] - cellRange[0] + 1;
        }
        else if (cellRange[0] >= formatRange[0] && cellRange[0] <= formatRange[2]) {
            deleteEndIndex = formatRange[2] - cellRange[0] + 1;
        }
        else if (cellRange[0] < formatRange[0] && cellRange[2] >= formatRange[0]) {
            deleteStartIndex = formatRange[0] - cellRange[0];
            deleteEndIndex = cellRange[2] - cellRange[0] + 1;
        }
        else if (cellRange[0] < formatRange[0] && cellRange[2] < formatRange[2]) {
            deleteStartIndex = (cellRange[2] - formatRange[0]) + (cellRange[2] - cellRange[0]) + 1;
            deleteEndIndex = cellRange[2] - cellRange[0] + 1;
        }
        return [formatRange[0] - deleteStartIndex, formatRange[1], formatRange[2] - deleteEndIndex, formatRange[3]];
    }
}
