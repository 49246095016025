import { getSheet, getColumn, isHiddenRow, getCell, setCell } from '../base/index';
import { cellValidation, applyCellFormat, isValidation, addHighlight, getCellAddress, validationHighlight } from '../common/index';
import { removeHighlight, checkIsFormula } from '../common/index';
import { getRangeIndexes, getUpdatedFormulaOnInsertDelete } from '../common/index';
import { updateCell, beforeInsert, beforeDelete } from '../common/index';
import { extend, isNullOrUndefined } from '@syncfusion/ej2-base';
/**
 * The `WorkbookHyperlink` module is used to handle Hyperlink action in Spreadsheet.
 */
var WorkbookDataValidation = /** @class */ (function () {
    /**
     * Constructor for WorkbookSort module.
     *
     * @param {Workbook} parent - Specifies the parent element.
     */
    function WorkbookDataValidation(parent) {
        this.parent = parent;
        this.addEventListener();
    }
    /**
     * To destroy the sort module.
     *
     * @returns {void}
     */
    WorkbookDataValidation.prototype.destroy = function () {
        this.removeEventListener();
        this.parent = null;
    };
    WorkbookDataValidation.prototype.addEventListener = function () {
        this.parent.on(cellValidation, this.ValidationHandler, this);
        this.parent.on(addHighlight, this.addHighlightHandler, this);
        this.parent.on(removeHighlight, this.removeHighlightHandler, this);
        this.parent.on(beforeInsert, this.beforeInsertDeleteHandler, this);
        this.parent.on(beforeDelete, this.beforeInsertDeleteHandler, this);
    };
    WorkbookDataValidation.prototype.removeEventListener = function () {
        if (!this.parent.isDestroyed) {
            this.parent.off(cellValidation, this.ValidationHandler);
            this.parent.off(addHighlight, this.addHighlightHandler);
            this.parent.off(removeHighlight, this.removeHighlightHandler);
            this.parent.off(beforeInsert, this.beforeInsertDeleteHandler);
            this.parent.off(beforeDelete, this.beforeInsertDeleteHandler);
        }
    };
    WorkbookDataValidation.prototype.ValidationHandler = function (args) {
        var onlyRange = args.range;
        var sheetName = '';
        var column;
        if (args.range.indexOf('!') > -1) {
            onlyRange = args.range.split('!')[1];
            sheetName = args.range.split('!')[0];
        }
        this.parent.dataValidationRange = this.parent.dataValidationRange + onlyRange + ',';
        var range = onlyRange.match(/[0-9]/g) ? this.getRange(args.range) : onlyRange;
        if (!range.match(/[0-9]/g)) {
            onlyRange = range;
        }
        var sheet = getSheet(this.parent, this.parent.getAddressInfo(sheetName + 'A1:A1').sheetIndex);
        var isfullCol = false;
        var maxRowCount = sheet.rowCount;
        var rangeArr = onlyRange.split(':');
        if (range.match(/\D/g) && !range.match(/[0-9]/g)) {
            rangeArr[0] += 1;
            rangeArr[1] += maxRowCount;
            onlyRange = rangeArr[0] + ':' + rangeArr[1];
            isfullCol = true;
        }
        else if (!range.match(/\D/g) && range.match(/[0-9]/g)) {
            rangeArr[0] = 'A' + rangeArr[0];
            rangeArr[1] = getCellAddress(0, sheet.colCount - 1).replace(/[0-9]/g, '') + rangeArr[1];
            onlyRange = rangeArr[0] + ':' + rangeArr[1];
        }
        if (!isNullOrUndefined(sheetName) && args.range.indexOf(sheetName) < 0) {
            args.range = sheetName + '!' + onlyRange;
        }
        args.range = isfullCol ? onlyRange : (args.range || sheet.selectedRange);
        var indexes = getRangeIndexes(args.range);
        if (isfullCol) {
            for (var colIdx = indexes[1]; colIdx <= indexes[3]; colIdx++) {
                column = getColumn(sheet, colIdx);
                isfullCol = isfullCol && args.isRemoveValidation && column && !column.validation ? false : true;
            }
        }
        if (isfullCol) {
            for (var colIdx = indexes[1]; colIdx <= indexes[3]; colIdx++) {
                column = getColumn(sheet, colIdx);
                if (args.isRemoveValidation && column && column.validation) {
                    delete (sheet.columns[colIdx].validation);
                }
                else {
                    if (!args.isRemoveValidation) {
                        if (isNullOrUndefined(column)) {
                            sheet.columns[colIdx] = getColumn(sheet, colIdx);
                        }
                        sheet.columns[colIdx].validation = {
                            operator: args.rules.operator,
                            type: args.rules.type,
                            value1: args.rules.value1,
                            value2: args.rules.value2,
                            inCellDropDown: args.rules.inCellDropDown,
                            ignoreBlank: args.rules.ignoreBlank
                        };
                    }
                }
            }
        }
        else {
            var cell = void 0;
            for (var rowIdx = indexes[0]; rowIdx <= indexes[2]; rowIdx++) {
                for (var colIdx = indexes[1]; colIdx <= indexes[3]; colIdx++) {
                    if (args.isRemoveValidation) {
                        cell = getCell(rowIdx, colIdx, sheet);
                        if (cell && cell.validation &&
                            !updateCell(this.parent, sheet, { cell: { validation: {} }, rowIdx: rowIdx, colIdx: colIdx })) {
                            delete (cell.validation);
                            this.parent.notify(applyCellFormat, { rowIdx: rowIdx, colIdx: colIdx, style: this.parent.getCellStyleValue(['backgroundColor', 'color'], [rowIdx, colIdx]) });
                        }
                    }
                    else {
                        cell = { validation: Object.assign({}, args.rules) };
                        updateCell(this.parent, sheet, { cell: cell, rowIdx: rowIdx, colIdx: colIdx });
                    }
                }
            }
        }
    };
    WorkbookDataValidation.prototype.addHighlightHandler = function (args) {
        this.InvalidDataHandler(args.range, false, args.td, args.isclearFormat);
    };
    WorkbookDataValidation.prototype.removeHighlightHandler = function (args) {
        this.InvalidDataHandler(args.range, true);
    };
    WorkbookDataValidation.prototype.getRange = function (range) {
        var indexes = getRangeIndexes(range);
        var sheet = this.parent.getActiveSheet();
        var maxColCount = sheet.colCount;
        var maxRowCount = sheet.rowCount;
        if (indexes[2] === maxRowCount - 1 && indexes[0] === 0) {
            range = range.replace(/[0-9]/g, '');
        }
        else if (indexes[3] === maxColCount - 1 && indexes[2] === 0) {
            range = range.replace(/\D/g, '');
        }
        return range;
    };
    WorkbookDataValidation.prototype.InvalidDataHandler = function (range, isRemoveHighlightedData, td, isclearFormat) {
        var isCell = false;
        var cell;
        var value;
        var sheet = this.parent.getActiveSheet();
        range = range || sheet.selectedRange;
        var indexes = range ? getRangeIndexes(range) : [];
        range = this.getRange(range);
        var isfullCol = false;
        if (range.match(/\D/g) && !range.match(/[0-9]/g)) {
            isfullCol = true;
        }
        var rowIdx = range ? indexes[0] : 0;
        var lastRowIdx = range ? indexes[2] : sheet.rows.length;
        for (rowIdx; rowIdx <= lastRowIdx; rowIdx++) {
            if (sheet.rows[rowIdx]) {
                var colIdx = range ? indexes[1] : 0;
                var lastColIdx = range ? indexes[3] : sheet.rows[rowIdx].cells.length;
                for (colIdx; colIdx <= lastColIdx; colIdx++) {
                    var validation = void 0;
                    if (sheet.rows[rowIdx].cells[colIdx]) {
                        var column = getColumn(sheet, colIdx);
                        cell = sheet.rows[rowIdx].cells[colIdx];
                        if (cell && cell.validation) {
                            validation = cell.validation;
                            if (isclearFormat && !validation.isHighlighted) {
                                return;
                            }
                            if (isRemoveHighlightedData) {
                                if (validation.isHighlighted) {
                                    cell.validation.isHighlighted = false;
                                }
                            }
                            else {
                                cell.validation.isHighlighted = true;
                            }
                        }
                        else if (column && column.validation) {
                            validation = column.validation;
                            if (isclearFormat && !validation.isHighlighted) {
                                return;
                            }
                            if (isRemoveHighlightedData && isfullCol) {
                                if (validation.isHighlighted) {
                                    column.validation.isHighlighted = false;
                                }
                            }
                            else if (isfullCol) {
                                column.validation.isHighlighted = true;
                            }
                        }
                        value = cell.value ? cell.value : '';
                        var range_1 = [rowIdx, colIdx];
                        var sheetIdx = this.parent.activeSheetIndex;
                        if (validation && this.parent.allowDataValidation) {
                            var validEventArgs = { value: value, range: range_1, sheetIdx: sheetIdx, isCell: isCell, td: td, isValid: true };
                            this.parent.notify(isValidation, validEventArgs);
                            var isValid = validEventArgs.isValid;
                            if (!isValid) {
                                if (!isHiddenRow(sheet, rowIdx)) {
                                    this.parent.notify(validationHighlight, {
                                        isRemoveHighlightedData: isRemoveHighlightedData, rowIdx: rowIdx, colIdx: colIdx, td: td
                                    });
                                }
                            }
                        }
                    }
                }
            }
        }
    };
    WorkbookDataValidation.prototype.beforeInsertDeleteHandler = function (args) {
        if (args.modelType === 'Sheet') {
            return;
        }
        var cell;
        var sheet;
        for (var i = 0, sheetLen = this.parent.sheets.length; i < sheetLen; i++) {
            sheet = this.parent.sheets[i];
            for (var j = 0, rowLen = sheet.rows.length; j < rowLen; j++) {
                if (sheet.rows[j] && sheet.rows[j].cells) {
                    for (var k = 0, cellLen = sheet.rows[j].cells.length; k < cellLen; k++) {
                        cell = sheet.rows[j].cells[k];
                        if (cell && cell.validation) {
                            var isInsert = args.name === 'beforeInsert';
                            var endIndex = args.index + (args.model.length - 1);
                            var isNewlyInsertedModel = args.modelType === 'Row' ? (j >= args.index && j <= endIndex) : (k >= args.index && k <= endIndex);
                            var eventArgs = void 0;
                            if (isInsert) {
                                eventArgs = { insertDeleteArgs: { startIndex: args.index, endIndex: args.index + args.model.length - 1, modelType: args.modelType, isInsert: true, sheet: getSheet(this.parent, args.activeSheetIndex) } };
                            }
                            else {
                                eventArgs = { insertDeleteArgs: { startIndex: args.start, endIndex: args.end, modelType: args.modelType, sheet: args.model } };
                            }
                            if (checkIsFormula(cell.validation.value1) && !isNewlyInsertedModel) {
                                eventArgs.cell = { formula: cell.validation.value1 };
                                this.parent.notify(getUpdatedFormulaOnInsertDelete, eventArgs);
                                cell.validation.value1 = eventArgs.cell.formula;
                            }
                            if (checkIsFormula(cell.validation.value2) && !isNewlyInsertedModel) {
                                eventArgs.cell = { formula: cell.validation.value2 };
                                this.parent.notify(getUpdatedFormulaOnInsertDelete, eventArgs);
                                cell.validation.value2 = eventArgs.cell.formula;
                            }
                            if (args.activeSheetIndex === i && isInsert) {
                                this.updateValidationForInsertedModel(args, sheet, j, k, cell.validation);
                            }
                        }
                    }
                }
            }
        }
    };
    WorkbookDataValidation.prototype.updateValidationForInsertedModel = function (args, sheet, rowIndex, colIndex, validation) {
        var endIndex = args.index + (args.model.length - 1);
        if (args.modelType === 'Column') {
            if ((args.insertType === 'before' && endIndex === colIndex - 1) || (args.insertType === 'after' && args.index - 1 === colIndex)) {
                for (var l = args.index; l <= endIndex; l++) {
                    setCell(rowIndex, l, sheet, { validation: extend({}, validation) }, true);
                }
            }
        }
        else if (args.modelType === 'Row') {
            if ((args.insertType === 'above' && endIndex === rowIndex - 1) || (args.insertType === 'below' && args.index - 1 === rowIndex)) {
                for (var l = args.index; l <= endIndex; l++) {
                    setCell(l, colIndex, sheet, { validation: extend({}, validation) }, true);
                }
            }
        }
    };
    /**
     * Gets the module name.
     *
     * @returns {string} string
     */
    WorkbookDataValidation.prototype.getModuleName = function () {
        return 'workbookDataValidation';
    };
    return WorkbookDataValidation;
}());
export { WorkbookDataValidation };
