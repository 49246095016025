import { keyDown, cellNavigate, renameSheet, filterCellKeyDown, getUpdateUsingRaf, isLockedCells } from '../common/index';
import { getCellIndexes, getRangeAddress, getRowHeight, getColumnWidth, getCell, isHiddenCol } from '../../workbook/index';
import { getRangeIndexes, getSwapRange, isHiddenRow, isColumnSelected, isRowSelected, skipHiddenIdx } from '../../workbook/index';
import { closest, isNullOrUndefined } from '@syncfusion/ej2-base';
/**
 * Represents keyboard navigation support for Spreadsheet.
 */
var KeyboardNavigation = /** @class */ (function () {
    /**
     * Constructor for the Spreadsheet Keyboard Navigation module.
     *
     * @private
     * @param {Spreadsheet} parent - Specify the spreadsheet
     */
    function KeyboardNavigation(parent) {
        this.parent = parent;
        this.addEventListener();
        /* code snippet */
    }
    KeyboardNavigation.prototype.addEventListener = function () {
        this.parent.on(keyDown, this.keyDownHandler, this);
        /* code snippet */
    };
    KeyboardNavigation.prototype.removeEventListener = function () {
        if (!this.parent.isDestroyed) {
            this.parent.off(keyDown, this.keyDownHandler);
        }
        /* code snippet */
    };
    KeyboardNavigation.prototype.keyDownHandler = function (e) {
        var _this = this;
        if (!this.parent.isEdit && (document.activeElement.classList.contains('e-spreadsheet') ||
            closest(document.activeElement, '.e-sheet')) && !closest(e.target, '.e-name-box') &&
            !(document.activeElement.classList.contains('e-ddl') && document.activeElement.classList.contains('e-input-focus'))) {
            var isNavigate = void 0;
            var scrollIdxes = void 0;
            var isRtl = this.parent.enableRtl;
            var sheet_1 = this.parent.getActiveSheet();
            var filterArgs = { e: e, isFilterCell: false };
            var actIdxes_1 = getCellIndexes(sheet_1.activeCell);
            if ([9, 37, 38, 39, 40, 33, 34].indexOf(e.keyCode) > -1) {
                e.preventDefault();
            }
            if (e.shiftKey) { // shift selection
                this.shiftSelection(e);
            }
            if (!e.shiftKey && e.altKey && (e.keyCode === 38 || e.keyCode === 40)) {
                this.parent.notify(filterCellKeyDown, filterArgs);
            }
            if ((!e.shiftKey && ((!isRtl && e.keyCode === 37) || (isRtl && e.keyCode === 39)))
                || (e.shiftKey && e.keyCode === 9)) { //left key
                if (actIdxes_1[1] > 0 || sheet_1.isProtected) {
                    if (sheet_1.isProtected && !sheet_1.protectSettings.selectUnLockedCells || !sheet_1.isProtected) {
                        actIdxes_1[1] -= 1;
                    }
                    else {
                        var idx = this.getNextUnlockedCell(e.keyCode, actIdxes_1);
                        actIdxes_1[1] = idx[1];
                        actIdxes_1[0] = idx[0];
                    }
                    isNavigate = true;
                }
                else {
                    var content = this.parent.getMainContent();
                    if (actIdxes_1[1] === 0 && content.scrollLeft && !isRtl) {
                        content.scrollLeft = 0;
                    }
                }
            }
            else if (e.shiftKey && e.keyCode === 13) { // Up key
                if (!this.parent.element.querySelector('.e-find-toolbar')) {
                    if (actIdxes_1[0] > 0 || sheet_1.isProtected) {
                        if (sheet_1.isProtected && !sheet_1.protectSettings.selectUnLockedCells || !sheet_1.isProtected) {
                            actIdxes_1[0] -= 1;
                        }
                        else {
                            var idx = this.getNextUnlockedCell(e.keyCode, actIdxes_1);
                            actIdxes_1[1] = idx[1];
                            actIdxes_1[0] = idx[0];
                        }
                        isNavigate = true;
                    }
                    else {
                        var content = this.parent.getMainContent().parentElement;
                        if (actIdxes_1[0] === 0 && content.scrollTop) {
                            content.scrollTop = 0;
                        }
                    }
                }
            }
            else if (!filterArgs.isFilterCell && !e.shiftKey && e.keyCode === 38) { // Up key
                if (sheet_1.isProtected || actIdxes_1[0] > 0) {
                    if (sheet_1.isProtected && !sheet_1.protectSettings.selectUnLockedCells || !sheet_1.isProtected) {
                        actIdxes_1[0] -= 1;
                    }
                    else {
                        var cellIdx = this.getNextUnlockedCell(e.keyCode, actIdxes_1);
                        actIdxes_1[1] = cellIdx[1];
                        actIdxes_1[0] = cellIdx[0];
                    }
                    isNavigate = true;
                }
                else {
                    var contentEle = this.parent.getMainContent().parentElement;
                    if (actIdxes_1[0] === 0 && contentEle.scrollTop) {
                        contentEle.scrollTop = 0;
                    }
                }
            }
            else if ((!e.shiftKey && ((!isRtl && e.keyCode === 39) || (isRtl && e.keyCode === 37))) || e.keyCode === 9) { // Right key
                var cell = getCell(actIdxes_1[0], actIdxes_1[1], sheet_1);
                if (cell && cell.colSpan > 1) {
                    actIdxes_1[1] += (cell.colSpan - 1);
                }
                if (actIdxes_1[1] < sheet_1.colCount - 1) {
                    if (sheet_1.isProtected && !sheet_1.protectSettings.selectUnLockedCells || !sheet_1.isProtected) {
                        actIdxes_1[1] += 1;
                    }
                    else {
                        var idx = this.getNextUnlockedCell(e.keyCode, actIdxes_1);
                        actIdxes_1[1] = idx[1];
                        actIdxes_1[0] = idx[0];
                    }
                    isNavigate = true;
                }
            }
            else if ((!filterArgs.isFilterCell && !e.shiftKey && e.keyCode === 40) || e.keyCode === 13) { // Down Key
                var cell = getCell(actIdxes_1[0], actIdxes_1[1], sheet_1);
                if (cell && cell.rowSpan > 1) {
                    actIdxes_1[0] += (cell.rowSpan - 1);
                }
                if (actIdxes_1[0] < sheet_1.rowCount - 1) {
                    if (sheet_1.isProtected && !sheet_1.protectSettings.selectUnLockedCells || !sheet_1.isProtected) {
                        actIdxes_1[0] += 1;
                    }
                    else {
                        var idx = this.getNextUnlockedCell(e.keyCode, actIdxes_1);
                        actIdxes_1[1] = idx[1];
                        actIdxes_1[0] = idx[0];
                    }
                    isNavigate = true;
                }
            }
            /* else if (e.keyCode === 36) {
                actIdxes[1] = 0;
                if (e.ctrlKey) {
                    actIdxes[0] = 0;
                }
                isNavigate = true;
                e.preventDefault();
            } else if (e.keyCode === 35 && e.ctrlKey) {
                actIdxes = [sheet.usedRange.rowIndex, sheet.usedRange.colIndex];
                scrollIdxes = [sheet.usedRange.rowIndex - this.parent.viewport.rowCount,
                    sheet.usedRange.colIndex - this.parent.viewport.colCount];
                isNavigate = true;
                e.preventDefault();
            } */
            else if (!e.shiftKey && (e.keyCode === 34 || e.keyCode === 33) && (!this.parent.scrollModule ||
                this.parent.scrollModule.isKeyScroll)) { // Page Up and Page Down
                var mainPanel = this.parent.element.querySelector('.e-main-panel');
                var diff = 0;
                if (e.keyCode === 34) { // Page Down
                    diff = mainPanel.getBoundingClientRect().height + mainPanel.scrollTop;
                }
                else { // Page Up
                    diff = mainPanel.scrollTop - mainPanel.getBoundingClientRect().height;
                    if (diff < 0) {
                        return;
                    }
                }
                var aRowIdx = getCellIndexes(this.parent.getActiveSheet().activeCell)[0];
                var topRow_1 = getCellIndexes(this.parent.getActiveSheet().paneTopLeftCell)[0];
                var selectDiff_1 = aRowIdx - topRow_1;
                if (this.parent.scrollModule) {
                    this.parent.scrollModule.isKeyScroll = false;
                }
                mainPanel.scrollTop = diff;
                getUpdateUsingRaf(function () {
                    topRow_1 = getCellIndexes(_this.parent.getActiveSheet().paneTopLeftCell)[0];
                    _this.parent.notify(cellNavigate, { range: [topRow_1 + selectDiff_1, actIdxes_1[1]], preventAnimation: true });
                });
            }
            if (isNavigate && (!this.parent.scrollModule || this.parent.scrollModule.isKeyScroll)) {
                if (e.keyCode === 40 || e.keyCode === 38 || e.keyCode === 13) {
                    while (isHiddenRow(sheet_1, actIdxes_1[0])) {
                        if (e.keyCode === 40 || (!e.shiftKey && e.keyCode === 13)) {
                            actIdxes_1[0] = actIdxes_1[0] + 1;
                        }
                        if (e.keyCode === 38 || (e.shiftKey && e.keyCode === 13)) {
                            actIdxes_1[0] = actIdxes_1[0] - 1;
                            if (actIdxes_1[0] < 0) {
                                return;
                            }
                        }
                    }
                }
                if (e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 9) {
                    while (isHiddenCol(sheet_1, actIdxes_1[1])) {
                        if (e.keyCode === 39 || (!e.shiftKey && e.keyCode === 9)) {
                            actIdxes_1[1] = actIdxes_1[1] + 1;
                        }
                        if (e.keyCode === 37 || (e.shiftKey && e.keyCode === 9)) {
                            actIdxes_1[1] = actIdxes_1[1] - 1;
                            if (actIdxes_1[1] < 0) {
                                return;
                            }
                        }
                    }
                }
                this.scrollNavigation(scrollIdxes || actIdxes_1, scrollIdxes ? true : false);
                var range_1 = getRangeAddress(actIdxes_1);
                var navigateFn = function (preventAnimation) {
                    if (range_1 === sheet_1.selectedRange) {
                        return;
                    }
                    _this.parent.setSheetPropertyOnMute(sheet_1, 'activeCell', range_1);
                    _this.parent.notify(cellNavigate, { range: actIdxes_1, preventAnimation: preventAnimation });
                };
                if (this.parent.scrollModule && this.parent.scrollModule.isKeyScroll) {
                    if (range_1 === sheet_1.selectedRange) {
                        return;
                    }
                    getUpdateUsingRaf(navigateFn.bind(this, true));
                }
                else {
                    navigateFn();
                }
            }
        }
        if (e.target.classList.contains('e-sheet-rename')) {
            if (e.keyCode === 32) {
                e.stopPropagation();
            }
            else if (e.keyCode === 13 || e.keyCode === 27) {
                this.parent.notify(renameSheet, e);
            }
        }
    };
    KeyboardNavigation.prototype.getNextUnlockedCell = function (keycode, actCellIdx) {
        var sheet = this.parent.getActiveSheet();
        var index;
        if (keycode === 39) {
            var colIdx = actCellIdx[1] + 1;
            var rowIdx = actCellIdx[0];
            if (actCellIdx[1] === sheet.usedRange.colIndex) {
                colIdx = 0;
                rowIdx = rowIdx + 1;
                if (actCellIdx[0] === sheet.usedRange.rowIndex) {
                    rowIdx = 0;
                }
            }
            if (actCellIdx[1] === sheet.usedRange.colIndex && actCellIdx[0] === sheet.usedRange.rowIndex) {
                rowIdx = colIdx = 0;
            }
            for (var i = rowIdx; i <= sheet.usedRange.rowIndex + 1; i++) {
                if (i > sheet.usedRange.rowIndex) {
                    i = 0;
                }
                for (var j = colIdx; j <= sheet.usedRange.colIndex; j++) {
                    if (!isLockedCells(this.parent, [i, j, i, j])) {
                        index = [i, j];
                        return index;
                    }
                    colIdx = j;
                }
                rowIdx = i;
                if (colIdx === sheet.usedRange.colIndex) {
                    colIdx = 0;
                    if (rowIdx === sheet.usedRange.rowIndex) {
                        rowIdx = 0;
                    }
                    else {
                        rowIdx++;
                    }
                }
            }
        }
        if (keycode === 37) { //Right Key
            var colIdx = actCellIdx[1] - 1;
            var rowIdx = actCellIdx[0];
            if (actCellIdx[1] === 0) {
                colIdx = sheet.usedRange.colIndex;
                rowIdx = rowIdx - 1;
                if (actCellIdx[0] === 0) {
                    rowIdx = sheet.usedRange.rowIndex;
                }
            }
            for (var i = rowIdx; i >= -1; i--) {
                if (i < 0) {
                    i = sheet.usedRange.rowIndex;
                }
                for (var j = colIdx; j >= 0; j--) {
                    if (!isLockedCells(this.parent, [i, j, i, j])) {
                        index = [i, j];
                        return index;
                    }
                    colIdx = j;
                }
                rowIdx = i;
                if (colIdx === 0) {
                    colIdx = sheet.usedRange.colIndex;
                    if (rowIdx === 0) {
                        rowIdx = sheet.usedRange.rowIndex;
                    }
                    else {
                        rowIdx--;
                    }
                }
            }
        }
        if (keycode === 40) { // Down Key
            var colIdx = actCellIdx[1];
            var rowIdx = actCellIdx[0] + 1;
            if (actCellIdx[0] === sheet.usedRange.rowIndex) {
                colIdx = colIdx + 1;
                rowIdx = 0;
                if (actCellIdx[1] === sheet.usedRange.colIndex) {
                    rowIdx = 0;
                }
            }
            if (actCellIdx[1] === sheet.usedRange.colIndex && actCellIdx[0] === sheet.usedRange.rowIndex) {
                rowIdx = colIdx = 0;
            }
            for (var i = colIdx; i <= sheet.usedRange.colIndex + 1; i++) {
                if (i > sheet.usedRange.colIndex) {
                    i = 0;
                }
                for (var j = rowIdx; j <= sheet.usedRange.rowIndex; j++) {
                    if (!isLockedCells(this.parent, [j, i, j, i])) {
                        index = [j, i];
                        return index;
                    }
                    rowIdx = j;
                }
                colIdx = i;
                if (rowIdx === sheet.usedRange.rowIndex) {
                    colIdx++;
                    if (colIdx === sheet.usedRange.colIndex) {
                        rowIdx = 0;
                    }
                    else {
                        rowIdx = 0;
                    }
                }
            }
        }
        if (keycode === 38) { // Up Key
            var colIdx = actCellIdx[1];
            var rowIdx = actCellIdx[0] - 1;
            if (actCellIdx[0] === 0) {
                colIdx = colIdx - 1;
                rowIdx = sheet.usedRange.rowIndex;
                if (actCellIdx[1] === 0) {
                    colIdx = sheet.usedRange.colIndex;
                }
            }
            for (var i = colIdx; i >= -1; i--) {
                if (i < 0) {
                    i = sheet.usedRange.colIndex;
                }
                for (var j = rowIdx; j >= 0; j--) {
                    if (!isLockedCells(this.parent, [j, i, j, i])) {
                        index = [j, i];
                        return index;
                    }
                    rowIdx = j;
                }
                colIdx = i;
                if (rowIdx === 0) {
                    rowIdx = sheet.usedRange.rowIndex;
                    if (colIdx === 0) {
                        colIdx = sheet.usedRange.colIndex;
                    }
                    else {
                        colIdx--;
                    }
                }
            }
        }
        return index;
    };
    KeyboardNavigation.prototype.shiftSelection = function (e) {
        var sheet = this.parent.getActiveSheet();
        var selectedRange = getRangeIndexes(sheet.selectedRange);
        var swapRange = getSwapRange(selectedRange);
        var noHidden = true;
        if (e.keyCode === 38) { // shift + up arrow
            for (var i = swapRange[1]; i <= swapRange[3]; i++) {
                var cell = getCell(selectedRange[2], i, this.parent.getActiveSheet());
                if (!isNullOrUndefined(cell) && cell.rowSpan && cell.rowSpan < 0) {
                    selectedRange[2] = selectedRange[2] - (Math.abs(cell.rowSpan) + 1);
                    noHidden = false;
                    break;
                }
            }
            if (noHidden) {
                selectedRange[2] = selectedRange[2] - 1;
            }
            if (selectedRange[2] < 0) {
                selectedRange[2] = 0;
            }
        }
        if (e.keyCode === 40) { // shift + down arrow
            for (var i = swapRange[1]; i <= swapRange[3]; i++) {
                var cell = getCell(selectedRange[2], i, this.parent.getActiveSheet());
                if (!isNullOrUndefined(cell) && cell.rowSpan && cell.rowSpan > 0) {
                    selectedRange[2] = selectedRange[2] + Math.abs(cell.rowSpan);
                    noHidden = false;
                    break;
                }
            }
            if (noHidden) {
                selectedRange[2] = selectedRange[2] + 1;
            }
        }
        if (e.keyCode === 39) { // shift + right arrow
            for (var i = swapRange[0]; i <= swapRange[2]; i++) {
                var cell = getCell(i, selectedRange[3], this.parent.getActiveSheet());
                if (!isNullOrUndefined(cell) && cell.colSpan && cell.colSpan > 0) {
                    selectedRange[3] = selectedRange[3] + Math.abs(cell.colSpan);
                    noHidden = false;
                    break;
                }
            }
            if (noHidden) {
                selectedRange[3] = selectedRange[3] + 1;
            }
        }
        if (e.keyCode === 37) { // shift + left arrow
            for (var i = swapRange[0]; i <= swapRange[2]; i++) {
                var cell = getCell(i, selectedRange[3], this.parent.getActiveSheet());
                if (!isNullOrUndefined(cell) && cell.colSpan && cell.colSpan < 0) {
                    selectedRange[3] = selectedRange[3] - (Math.abs(cell.colSpan) + 1);
                    noHidden = false;
                    break;
                }
            }
            if (noHidden) {
                selectedRange[3] = selectedRange[3] - 1;
            }
            if (selectedRange[3] < 0) {
                selectedRange[3] = 0;
            }
        }
        if (e.shiftKey && e.ctrlKey && !this.parent.scrollSettings.enableVirtualization) { // ctrl + shift selection
            var usedRange = [sheet.usedRange.rowIndex, sheet.usedRange.colIndex];
            if (e.keyCode === 37) {
                if (selectedRange[3] <= usedRange[1]) {
                    selectedRange[3] = 0;
                }
                else {
                    selectedRange[3] = usedRange[1];
                }
            }
            if (e.keyCode === 38) {
                if (selectedRange[2] <= usedRange[0]) {
                    selectedRange[2] = 0;
                }
                else {
                    selectedRange[2] = usedRange[0];
                }
            }
            if (e.keyCode === 39) {
                if (selectedRange[3] <= usedRange[1]) {
                    selectedRange[3] = usedRange[1];
                }
                else {
                    selectedRange[3] = this.parent.getActiveSheet().colCount;
                }
            }
            if (e.keyCode === 40) {
                if (selectedRange[2] <= usedRange[0]) {
                    selectedRange[2] = usedRange[0];
                }
                else {
                    selectedRange[2] = this.parent.getActiveSheet().rowCount;
                }
            }
        }
        if (e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 38 || e.keyCode === 40) {
            var activeIdxes = getCellIndexes(sheet.activeCell);
            while (isHiddenRow(this.parent.getActiveSheet(), selectedRange[2])) {
                if (e.keyCode === 40) {
                    selectedRange[2] = selectedRange[2] + 1;
                }
                if (e.keyCode === 38) {
                    selectedRange[2] = selectedRange[2] - 1;
                }
            }
            this.parent.selectRange(getRangeAddress(selectedRange));
            this.scrollNavigation([isColumnSelected(sheet, selectedRange) ? activeIdxes[0] : selectedRange[2],
                isRowSelected(sheet, selectedRange) ? activeIdxes[1] : selectedRange[3]], false);
        }
    };
    KeyboardNavigation.prototype.scrollNavigation = function (actIdxes, isScroll) {
        if (!this.parent.allowScrolling) {
            return;
        }
        var x = this.parent.enableRtl ? -1 : 1;
        var cont = this.parent.getMainContent().parentElement;
        var hCont = this.parent.getScrollElement();
        var sheet = this.parent.getActiveSheet();
        var selectedRange = getSwapRange(getRangeIndexes(sheet.selectedRange));
        var topLeftIdxes = getCellIndexes(sheet.topLeftCell);
        var frozenRow = this.parent.frozenRowCount(sheet);
        var frozenCol = this.parent.frozenColCount(sheet);
        var paneTopLeftIdxes = getCellIndexes(sheet.paneTopLeftCell);
        var topIdx = skipHiddenIdx(sheet, actIdxes[0] < frozenRow ? topLeftIdxes[0] : paneTopLeftIdxes[0], true);
        var leftIdx = actIdxes[1] < frozenCol ? topLeftIdxes[1] : paneTopLeftIdxes[1];
        var offsetTopSize = this.parent.scrollModule.offset.top.size;
        if (cont.scrollTop) {
            if (frozenRow && actIdxes[0] !== selectedRange[2]) {
                if (actIdxes[0] === frozenRow) {
                    cont.scrollTop = 0;
                    return;
                }
                if (actIdxes[0] === frozenRow - 1) {
                    cont.scrollTop = 0;
                }
            }
            else if (actIdxes[0] === skipHiddenIdx(sheet, 0, true)) {
                cont.scrollTop = 0;
                return;
            }
        }
        if (hCont && hCont.scrollLeft) {
            if (frozenCol && actIdxes[1] !== selectedRange[3]) {
                if (actIdxes[1] === frozenCol) {
                    hCont.scrollLeft = 0;
                    return;
                }
                if (actIdxes[1] === frozenCol - 1) {
                    hCont.scrollLeft = 0;
                }
            }
            else if (actIdxes[1] === skipHiddenIdx(sheet, 0, true, 'columns')) {
                hCont.scrollLeft = 0;
                return;
            }
        }
        if (this.getBottomIdx(topIdx) <= actIdxes[0] || isScroll) {
            cont.scrollTop = offsetTopSize + getRowHeight(sheet, skipHiddenIdx(sheet, paneTopLeftIdxes[0], true), true);
            this.parent.scrollModule.isKeyScroll = false;
        }
        else if (topIdx > actIdxes[0]) {
            cont.scrollTop = offsetTopSize - Math.ceil(getRowHeight(sheet, actIdxes[0], true));
            this.parent.scrollModule.isKeyScroll = false;
        }
        var scrollLeftIdx = this.getRightIdx(leftIdx);
        if ((scrollLeftIdx <= actIdxes[1] || isScroll) && hCont) {
            hCont.scrollLeft += getColumnWidth(sheet, scrollLeftIdx, null, true) * x;
            this.parent.scrollModule.isKeyScroll = false;
        }
        else if (leftIdx > actIdxes[1] && hCont) {
            hCont.scrollLeft -= getColumnWidth(sheet, actIdxes[1], null, true) * x;
            this.parent.scrollModule.isKeyScroll = false;
        }
    };
    KeyboardNavigation.prototype.getBottomIdx = function (top) {
        var hgt = 0;
        var sheet = this.parent.getActiveSheet();
        var viewPortHeight = (sheet.frozenRows ? this.parent.viewport.height - this.parent.sheetModule.getColHeaderHeight(sheet, true) : this.parent.viewport.height) - 17 || 20;
        for (var i = top;; i++) {
            hgt += getRowHeight(sheet, i, true);
            if (hgt >= viewPortHeight) {
                return i;
            }
        }
    };
    KeyboardNavigation.prototype.getRightIdx = function (left) {
        var width = 0;
        var sheet = this.parent.getActiveSheet();
        var contWidth = this.parent.getMainContent().parentElement.offsetWidth -
            this.parent.sheetModule.getRowHeaderWidth(sheet) - this.parent.sheetModule.getScrollSize();
        for (var i = left;; i++) {
            width += getColumnWidth(sheet, i, null, true);
            if (width >= contWidth) {
                return i;
            }
        }
    };
    /**
     * For internal use only - Get the module name.
     *
     * @private
     * @returns {string} - Get the module name.
     */
    KeyboardNavigation.prototype.getModuleName = function () {
        return 'keyboardNavigation';
    };
    KeyboardNavigation.prototype.destroy = function () {
        this.removeEventListener();
        this.parent = null;
    };
    return KeyboardNavigation;
}());
export { KeyboardNavigation };
